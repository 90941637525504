.checkboxList {
    display: flex;
    flex-direction: column;
}

.checkboxItem {
    padding-block: 2px;
    padding-inline: 8px;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
}

.selectedItems {
    font-weight: bold;
}

.buttonsRow {
    display: inline-flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 8px;
}

.button {
    /* flex-grow: 1; */
    width: 50%
}

.dropRowOutline {
    outline-style: solid;
    outline-width: 1px;
    outline-color: red;
}