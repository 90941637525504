.container {
    position: sticky;
    z-index: 4;
    height: 100vh;
}

.drawerContent {
    overflow-y: auto;
    overflow-x: auto;
    /* overflow-x: hidden; */
    height: 100%;
}

.itemIcon {
    margin-top: 3px;
    margin-bottom: 3px;
}

.itemText {
    padding-left: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.drawerMenu {
    /* padding-left: 10px; */
    width: 48px;
    padding: 4px;
}

.menuText {
    /* width: 48px; */
    padding: 6px;
    font-weight: bold;
    font-size: large;
}

.logo {
    justify-content: center;
    align-items: center;
    padding: 8px;
}