.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    row-gap: 10px;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    justify-content: center;
    align-items: center;
    margin: 16px;
}

.signInButton {
    justify-content: center;
    align-items: center;
}

.backOffice {
    font-size: 20px;
    font-weight: bold;
}

.cet {
    justify-content: center;
    align-items: center;
    /* align-self: center; */
}