.notificationGroup {
    top: 0;
    right: 16px;
    margin-top: 12px;
    margin-right: 12px;
}

.animationContainer {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.notification {
    align-self: flex-end;
    font-size: 16px !important;
}

.loader {
    top: 50%;
    left: 50%;
    margin-top: -32px;
    margin-left: -32px;
    z-index: 10003;
    position: fixed;
}