$kendo-is-dark-theme: true;
$kendo-color-primary: #f36618;
$kendo-color-secondary: #666666;
$kendo-color-info: #0058e9;
$kendo-color-success: #37b400;
$kendo-color-warning: #ffc000;
$kendo-color-error: #f31700;
$kendo-body-text: #ffffff;
$kendo-body-bg: #54595F;
$kendo-subtle-text: #e0e0e0;
$kendo-disabled-text: #d0d0d0;
$kendo-component-text: #ffffff;
$kendo-component-bg: #54595F;
$kendo-component-bg-light: lighten($kendo-component-bg, 2%);
$kendo-component-bg-dark: darken($kendo-component-bg, 2%);
$kendo-base-text: #ffffff;
$kendo-base-bg: $kendo-body-bg;
$kendo-hover-text: #ffffff;
$kendo-hover-bg: #202020;
$kendo-selected-text: #ffffff;
$kendo-selected-bg: #f36618;
$kendo-button-text: #ffffff;
$kendo-button-bg: #404040;
$kendo-link-text: #f36618;
$kendo-series-a: #f36618;
$kendo-series-b: #ffe162;
$kendo-series-c: #4cd180;
$kendo-series-d: #4b5ffa;
$kendo-series-e: #ac58ff;
$kendo-series-f: #ff5892;

$kendo-enable-typography: true;

$kendo-drawer-hover-bg: $kendo-color-primary;
$kendo-drawer-focus-bg: $kendo-color-primary;
$kendo-drawer-selected-bg: $kendo-color-primary;
$kendo-drawer-selected-hover-bg: $kendo-color-primary;
$kendo-selected-text: black;
$kendo-drawer-hover-text: black;
$kendo-selected-icon-text: $kendo-color-primary;

@import "~@progress/kendo-theme-default/dist/all.scss";

$trash-color: #e25017;

a {
  color: $kendo-link-text;

  &:hover {
    color: $kendo-link-hover-text;
  }
}

.k-drawer-item {
  & .fa-solid {
    color: $kendo-color-primary;
  }
}

.k-drawer-item {

  &:focus,
  &.k-selected,
  &:hover {
    & .fa-solid {
      color: white;
    }
  }
}

fieldset {
  background-color: $kendo-component-bg-light
}

.k-drawer {
  background-color: $kendo-component-bg-light
}

.k-list {
  background-color: $kendo-component-bg-light
}

.k-listview {
  background-color: $kendo-component-bg-light
}

.k-drawer-container {
  background-color: $kendo-base-bg;
}

.k-input {
  background-color: $kendo-component-bg-light;
}

textarea {
  background-color: $kendo-component-bg-light;
}

.k-checkbox {
  background-color: $kendo-component-bg-light;
}

.k-switch-thumb {
  background-color: $kendo-component-bg-light !important;
}

.k-picker-solid {
  background-color: $kendo-component-bg-dark;
}

.fill-height textarea.k-input-inner {
  height: 100% !important;
}

// Minden dropdown list alapértelmezett háttere
// .k-picker {
//   background-color: $kendo-color-primary;
// }

// Hogy a dialog címek ...-ozva legyenek
.k-window-title {
  display: inline-block;
}

.k-drawer-wrapper{
  height: 100%;
}

.k-drawer-items{
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #151950;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.trash-icon{
  color: $trash-color;
}