.taskBoard {
    overflow-x: visible;
    user-select: none;
}

.taskBoardHeader {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
}

.taskBoardActions {
    display: flex;
    align-items: flex-end;
    height: 28px;
    column-gap: 8px;
}

.textSearchField {
    width: 180px !important;
}

.userSearch {
    width: 180px !important;
}

.dateRangePicker {
    width: auto !important;
}

.popupList {
    width: 100px;
}

.popupListItem {
    padding-inline: 8px;
    padding-block: 4px;
}

.categoryColumn {
    /* background-color: transparent; */
    min-height: 85vh;
    min-width: 180px;
}

.cardList {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.card {
    margin-left: 4px;
    margin-right: 8px;
    margin-top: 4px;
    margin-bottom: 6px;
    display: inline-block;
    /* flex-direction: column; */
}

.cardTitle {
    color: #f36618;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    display: inline;
}

.cardTitle:hover {
    text-decoration: underline;
}

.cardData {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-weight: 500;
    font-size: 16px;
}

.cardDataIcon {
    font-weight: 300 !important;
    font-size: 18px;
}

.cardDataText {
    margin-left: 4px;
    margin-right: 12px;
}