.container {
    /* overflow: hidden; */
    padding-left: 16px;
    padding-right: 32px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
}

.titleMenu {
    display: flex;
    align-self: flex-start;
    margin-bottom: 16px;
    /* padding-left: 32px; */
}

.table {
    /* overflow: hidden; */
    /* height: 100%; */
}

.tabStripContent {
    /* padding-inline: 16px; */
    /* padding-inline: 0; */
}

.statisticsTable {
    margin-bottom: 32px;
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.dialogForm {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.dialogActionsBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
}

.dialogTitle {
    /* width: 100px; */
}

.gridCell {
    padding-inline: 12px !important;
}


.pdfExportFont {
    font-family: "DejaVu Sans", "Arial", sans-serif;
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}