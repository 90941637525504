.contentContainer {
    /* margin-top: 16px; */
    padding-top: 16px;
    display: flex;
    /* border-top: 1px solid gray; */
}

.menuRow {
    padding-bottom: 16px;
    border-bottom-style: solid;
    border-width: 1px;
}

.saveButton {
    float: right;
    margin-top: 12px;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.addButton {
    align-self: start;
}

.parentColumn {
    vertical-align: top !important;
    text-align: left !important;
    padding-left: 10px;
    /* display: grid; */
    /* grid-template-columns: 40px 200px; */
    /* flex-direction: row;
    display: flex; */
    /* height: 100%; */
}

.childColumn {
    /* display: grid; */
    /* grid-template-columns: 300px 40px; */
    /* vertical-align: top !important; */
    /* text-align: left !important; */
    flex-direction: row;
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
}

/* make list background transparent if in table row */
tr .childList {
    background-color: rgba(0, 0, 0, 0);
}

.editButton {
    align-self: start;
    /* float: left */
}

.childAddButton {
    align-self: flex-start;
}

.colorBox {
    height: 20px;
    border: 1px solid;
}

.taskTypeEditContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.taskTypeFormContainer {
    display: grid;
    grid-template-columns: 200px 500px;
    row-gap: 12px;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
}

.taskTypeTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.taskTypeTable {
    width: 700px;
}

.shortField {
    width: 200px
}

.labelTooltip{
    display: flex;
    gap: 12px;
}