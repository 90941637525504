.modal {
    top: 5vh;
    left: 10vw;
    height: 90vh;
    width: 80vw;
}

.header {
    border-left: 8px solid;
    padding: 4px;
}

.windowTitleBar {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 4px;
}

.titleBarText {
    font-size: 18px;
    margin: 0px;
    align-self: center;
}

.titleBarActions {
    display: flex;
    column-gap: 6px;
}

.headerRow {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding-block: 4px;
    margin-right: 8px;
}

.headerIcon {
    align-self: center;
    font-weight: 500 !important;
    font-size: 20px;
}

.idContainer {
    display: flex;
}

.idText {
    align-self: center;
    margin-left: 2px;
    font-weight: 500;
    font-size: 20px;
}

.input {
    font-size: 18px;
}

.dateContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.body {
    margin: 12px;
    height: 100%;
}

.dataTab {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.dataFields {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    height: calc(100% - 40px);
    width: 100%;
}

.descriptionArea {
    height: 100%;
    overflow: auto;
}

.customFields {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    overflow-y: auto;
}

.customFieldRow {
    display: flex;
    column-gap: 8px;
}

.customFieldLabel {
    font-size: 14px;
    width: 220px;
    word-wrap: break-word;
}

.emptyTab {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.nameColumn {
    width: 180px;
}

.dateColumn {
    width: 180px;
}

.fieldNameColumn {
    width: 25%;
}

.fieldValueColumn {
    width: 25%;
}

.attachmentContainer {
    display: flex;
    flex-direction: column;
}

.attachFileButton {
    width: fit-content;
    margin-bottom: 12px;
}